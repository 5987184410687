import { LanguageContentType } from '@lib/types/geo-lp'

export const BANGLA_CONTENT: LanguageContentType = {
  bookTrialClassCTA: 'ফ্রি ক্লাস বুক করুন।',
  heroFold: {
    formatHeading() {
      return `বাংলাদেশের শিশু-কিশোরদের জন্য সর্বসেরা অনলাইন কোডিং ক্লাস`
    },
    subHeading: (
      <>এক থেকে দ্বাদশ শ্রেণীর শিক্ষার্থীদের জন্য প্রযোজ্য কোডিং কোর্স</>
    ),
    stats: [
      {
        statNumber: '500,000+',
        statSubHeading: 'ছাত্র',
      },
      {
        statNumber: '70+',
        statSubHeading: 'দেশ',
      },
    ],

    ratings: [
      {
        ratingNumber: '8.৯',
        ratingSubHeading: 'শিক্ষার্থী এবং অভিভাবকদের দেয়া গড় রেটিং',
      },
      {
        ratingNumber: '8.৮',
        ratingSubHeading: 'পাঠ্যক্রমের গড় রেটিং ',
      },
    ],
    form: {
      gradeFieldPlaceholder: 'বর্তমান শ্রেণী',
      phoneFieldLabel: 'আপনার ফোন নাম্বারটি দিন',
      ctaText: 'ফ্রি ক্লাস বুক করুন',
      hasLaptopField: {
        label: 'আপনার কি ক্লাসের জন্য ল্যাপটপ/পিসি আছে?',
        option1: 'হ্যাঁ',
        option2: 'না',
      },
      termsAndConditions:
        'এই নিবন্ধন এর মাধ্যমে আপনি আমাদের     <a href="/terms" class="hover:text-orange" target="_blank">সেবা পাবার শর্তসমূহ</a> এবং <a href="/privacy-policy" class="hover:text-orange" target="_blank">গোপনীয়তার নীতিসমূহ</a> এর সাথে একমত হয়েছেন। একইসাথে নিশ্চিত করছেন যে নিবন্ধনে আপনার অভিভাবক এর সম্মতি আছে। সকল গুরুত্বপূর্ণ আপডেট আপনার কাছে হোয়াটস্অ্যাপ্-এর মাধ্যমে পৌঁছে যাবে।',
    },
  },
  partnersFold: {
    builtBySectionHeading: 'যে সকল প্রতিষ্ঠান-এর  প্রাক্তনদের দ্বারা তৈরি',
    partnershipSectionHeading: 'যাদের অংশীদারিত্বে তৈরি',
    stemSectionHeading: 'STEM.org দ্বারা স্বীকৃত',
    backedBySectionHeading: 'দ্বারা সমর্থিত',
  },
  learnToCodeFold: {
    heading: {
      normalText: 'কোডিং শিখুন আর',
      underlinedText: 'সনদপত্র অর্জন করুন',
    },
    subHeading:
      'বিশ্বব্যাপী মর্যাদাপূর্ণ বিভিন্ন প্রতিষ্ঠান থেকে সনদপত্র অর্জন করুন এবং গর্বের সাথে নিজের কোডিং দক্ষতা পৃথিবীকে দেখান',
  },
  testimonialsFold: {
    heading: {
      normalText: 'কেন আমাদের ছাত্র-ছাত্রিরা',
      underlinedText: 'Codingal কে ভালবাসে',
      wholeHeadingInJsx: (
        <>
          কেন আমাদের ছাত্র-ছাত্রিরা{' '}
          <span className="underlined-text">Codingal কে ভালবাসে</span>
        </>
      ),
    },
    contents: [
      {
        name: 'রে',
        imageName: 'Ray',
        subheading: '৩য় শ্রেণী, Codingal ছাত্র',
        content:
          'Codingal-এ শিখতে আমি ভালবাসি। এখানের পড়াশুনা যেমন আনন্দের, শিক্ষকরাও তেমনই যত্নশীল',
      },
      {
        name: 'বিলি',
        imageName: 'Billie',
        subheading: '৫ম শ্রেণী, Codingal ছাত্রি',
        content:
          'Codingal-এর ক্লাসগুলো খুবি আনন্দময়। আমি এখন কোডিং-এর মাধ্যমে বিভিন্ন কিছু তৈরি করা খুবি উপভোগ করি',
      },
      {
        name: 'সারানশ্',
        imageName: 'Saaransh',
        subheading: '৪র্থ শ্রেণী, Codingal ছাত্র',
        content:
          'ইতিমধ্যেই আমার দু-দুটো অ্যাপস্ গুগল প্লে-ষ্টোরে প্রকাশিত হয়েছে। আমি খুবি খুশি যে আমি কোডিং শিখছি Codingal হতে',
      },
      {
        name: 'ইকুমি',
        imageName: 'Ikumi',
        subheading: 'Codingal অভিভাবক',
        content:
          'Codingal এর কোর্স গুল কাঠামোগত দিক দিয়ে উন্নত, যা আমার সন্তান এর কোডিং শেখাকে করেছে আনন্দময় পদ্ধতিগত ভাবে এরাই সেরা এবং দ্রুততম',
      },
      {
        name: 'হাদিকাহ রিয়াদ',
        imageName: 'hadiqah',
        subheading: '১ম শ্রেণী, Codingal ছাত্র',
        content:
          'কোডিংগালের শিক্ষকরা খুবই জ্ঞানী, উষ্ণ এবং সহায়ক। আমি তাদের নির্দেশনায় কোড শিখতে পেরে খুশি I',
      },
      {
        name: 'হাফসা লোধি',
        imageName: 'hafsa',
        subheading: 'Codingal অভিভাবক',
        content:
          'কোডিংগালের কাস্টমাইজযোগ্য কোর্সগুলি আমার সন্তানকে একটি বাস্তব সুবিধা দেয়: একাডেমিকভাবে, সামাজিকভাবে এবং প্রযুক্তিতে। অত্যন্ত বাঞ্ছনীয়!',
      },
    ],
    reviewLinkText: 'আরো পর্যালোচনা দেখুন',
  },
  whyCodingalFold: {
    heading: {
      normalText: 'কেন Codingal',
      underlinedText: 'কে বেছে নিবেন',
    },
    contents: [
      {
        heading: 'দ্বারা পরিচালিত',
        coloredHeading: 'সেরা প্রশিক্ষকগন',
        imgLabel: 'guided-by-expert-instructors',
        subHeading:
          'আপনার কোডিং যাত্রাকে করবে সহজ ও সাবলীল করে তুলতে উচ্চপ্রশিক্ষিত ও সেরা কম্পিউটার বিজ্ঞান শিক্ষকদের সাথে থাকছে একক সেশন।',
      },
      {
        heading: 'সর্বাধিক বিজ্ঞানসম্মত',
        coloredHeading: 'পাঠ্যক্রমের শক্তিতে গঠিত',
        imgLabel: 'powered-by-scientific-curriculum',
        subHeading:
          "বিশ্বের একমাত্র কোডিং পাঠ্যক্রম যা BIDE, STEAM এবং Bloom's Taxonomy-এর উপর ভিত্তি করে তৈরি, যার মাধ্যমে শিশু-কিশোরেরা কোডিং শিখবে বৈজ্ঞানিক পদ্ধতিতে।",
      },
      {
        heading: 'পাঠ্যক্রমের গতি যা আপনার',
        coloredHeading: 'শিক্ষাশৈলীর সাথে মানানসই।',
        imgLabel: 'pace-of-instruction-that-matches-your-learning-style',
        subHeading:
          'এখানে শেখানোর গতি আপনার শেখার গতির সাথে মানানসই, যার কারনে আপনি কোর্সটি থেকে পাবেন সর্বোচ্চ মূল্য।',
      },
    ],
  },
  footer: {
    supprotHeading: 'প্রশ্ন আছে? আমাদের লিখে পাঠান',
  },
}
