import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { LanguageContentType } from '@lib/types/geo-lp'
import { i18n } from '@lingui/core'
import { defineMessage, t, Trans } from '@lingui/macro'

export const ENGLISH_TESTIMONIAL_CONTENTS = {
  default: [
    {
      name: defineMessage({ id: '[Name]Hadiqah', message: 'Hadiqah' }),
      imageName: 'hadiqah',
      subheading: defineMessage({ message: `Grade 1 - Codingal Student` }),
      content: defineMessage({
        message: `I love my coding classes, my teacher makes learning to code a fun activity.`,
      }),
    },
    {
      name: defineMessage({ id: '[Name]Melvin', message: 'Melvin' }),
      imageName: 'melvin',
      subheading: defineMessage({ message: `Grade 4 - Codingal Student` }),
      content: defineMessage({
        message: `I now have two apps published on the Google Play Store. I'm glad to be learning coding with Codingal.`,
      }),
    },
    {
      name: defineMessage({ id: '[Name]Shreyansh', message: 'Shreyansh' }),
      imageName: 'shreyansh',
      subheading: defineMessage({ message: `Grade 7 - Codingal Student` }),
      content: defineMessage({
        message: `Thanks to Codingal, I have learnt to code with utmost accuracy and in a very engaging manner.`,
      }),
    },
    {
      name: defineMessage({ id: '[Name]Ikumi', message: 'Ikumi' }),
      imageName: 'Ikumi',
      subheading: defineMessage({ message: `Codingal Parent` }),
      content: defineMessage({
        message: `Codingal's well-structured courses have made coding fun for my son. They're the best and the quickest.`,
      }),
    },
  ],
  PK: [
    {
      name: defineMessage({ id: '[Name]Hadiqah', message: 'Hadiqah' }),
      imageName: 'picture1',
      subheading: defineMessage({ message: `Grade 1 - Codingal Student` }),
      content: defineMessage({
        message: `I now have two apps published on the Google Play Store. I'm glad to be learning coding with Codingal.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Sheikh Adheen',
        message: 'Sheikh Adheen',
      }),
      imageName: 'picture2',
      subheading: defineMessage({ message: `Grade 7 - Codingal Student` }),
      content: defineMessage({
        message: `I recently joined Codingal's Game development course, and I look forward to attending my classes. I love learning so many new things like code, developing games, and much more.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]M.Bilal Adil',
        message: 'M.Bilal Adil',
      }),
      imageName: 'picture3',
      subheading: defineMessage({ message: `Grade 4 - Codingal Student` }),
      content: defineMessage({
        message: `The easy-to-grasp and detailed app development course by Codingal is the perfect go-to solution to learn to code. Thank you, Codingal.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Adil Tanveer',
        message: 'Adil Tanveer',
      }),
      imageName: 'picture4',
      subheading: defineMessage({ message: `Codingal Parent` }),
      content: defineMessage({
        message: `The curriculum at Codingal is thoughtful. The teachers are highly qualified and patient. The customer service is excellent and flexible scheduling. Highly recommended.`,
      }),
    },
  ],
  JP: [
    {
      name: defineMessage({ id: '[Name]Ray', message: 'Ray' }),
      imageName: 'picture1',
      subheading: defineMessage({ message: `Grade 3, Codingal Student` }),
      content: defineMessage({
        message: `I love learning with Codingal. It's always fun and the teacher is nice and kind.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Raima Devkota',
        message: 'Raima Devkota',
      }),
      imageName: 'picture2',
      subheading: defineMessage({ message: `Grade 5, Codingal Student` }),
      content: defineMessage({
        message: `I like learning to code and make different games. It is fun and challenging, thank you Codingal.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Daivat Upreti',
        message: 'Daivat Upreti',
      }),
      imageName: 'picture3',
      subheading: defineMessage({ message: `Grade 4, Codingal Student` }),
      content: defineMessage({
        message: `I love dragging and dropping the sprites. My Scratch coding teacher at Codingal is very knowledgeable and so patient and always helpful.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Ikumi',
        message: 'Ikumi',
      }),
      imageName: 'picture4',
      subheading: defineMessage({ message: `Codingal Parent` }),
      content: defineMessage({
        message: `Codingal's well-structured courses have made coding fun for my son. They're the best and the quickest.`,
      }),
    },
  ],
  US: [
    {
      name: defineMessage({
        id: '[Name]Mike',
        message: 'Mike',
      }),
      imageName: 'mike',
      subheading: defineMessage({ message: `Grade 5, Codingal Student` }),
      content: defineMessage({
        message: `My teacher is very patient with me and explains the concepts multiple times whenever I don’t understand them.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Ray',
        message: 'Ray',
      }),
      imageName: 'ray',
      subheading: defineMessage({ message: `Grade 3, Codingal Student` }),
      content: defineMessage({
        message: `I love learning with Codingal. It's always fun and the teacher is nice and kind.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]Emma Mason',
        message: 'Emma Mason',
      }),
      imageName: 'emma-mason',
      subheading: defineMessage({ message: `Grade 2, Codingal Student` }),
      content: defineMessage({
        message: `I am learning to create animation on Scratch platform. It is super fun and my teacher helps me with my projects.`,
      }),
    },
    {
      name: defineMessage({
        id: '[Name]David Henry',
        message: 'David Henry',
      }),
      imageName: 'david-henry',
      subheading: defineMessage({ message: `Codingal Parent` }),
      content: defineMessage({
        message: `I really appreciate the hard work and efforts of the teacher. Learning to code is a new experience for my kid and she enjoys the classes.`,
      }),
    },
  ],
}

export const CODING_PROJECT_TESTIMONIAL = [
  {
    name: defineMessage({ message: 'Ojas Sharma' }),
    imageName: 'ojas',
    subheading: defineMessage({ message: `Grade 5, Codingal Student` }),
    content: defineMessage({
      message: `The IIT Roorkee coding program was incredible! I learned by building real coding projects, and it made learning so much fun.`,
    }),
  },
  {
    name: defineMessage({ message: 'Aarav S' }),
    imageName: 'aarav',
    subheading: defineMessage({ message: `Grade 10, Codingal Student` }),
    content: defineMessage({
      message: `I collaborated with peers & learned from expert teachers. The IIT Bombay coding competition boosted my creativity, critical thinking, and coding abilities.`,
    }),
  },
  {
    name: defineMessage({ message: 'Priyanka Sahni' }),
    imageName: 'priyanka',
    subheading: defineMessage({ message: `Codingal Parent` }),
    content: defineMessage({
      message: `The IIT Guwahati Hackathon created a strong foundation for my daughter's coding journey. The collaborative learning environment was remarkable`,
    }),
  },
  {
    name: defineMessage({ message: 'Riya' }),
    imageName: 'riya',
    subheading: defineMessage({ message: `Grade 4, Codingal Student` }),
    content: defineMessage({
      message: `I found Codingal's competitions and projects engaging. I've built my own website and learned so much about how the internet works. I'm enjoying my classes`,
    }),
  },
]

export const ENGLISH_CONTENT: LanguageContentType<LinguiJSMessageDescriptor> = {
  bookTrialClassCTA: defineMessage({ message: `Try a free class` }),
  seeMoreReviewsCTA: defineMessage({ message: `See more reviews` }),
  heroFold: {
    formatHeading(country) {
      const inCountryString = t({
        message: `in ${country}`,
        id: 'in-country',
      })
      return t`Best online coding classes for kids ${
        country ? inCountryString : ''
      }`
    },
    subHeading: (
      <Trans>
        Coding courses for <span className="font-600">Grade 1-12</span>
      </Trans>
    ),
    stats: [
      {
        statNumber: defineMessage({ message: `500,000+` }),
        statSubHeading: defineMessage({ message: `Students` }),
      },
      {
        statNumber: defineMessage({ message: `70+` }),
        statSubHeading: defineMessage({ message: `Countries` }),
      },
    ],
    ratings: [
      {
        ratingNumber: i18n.number(4.9),
        ratingSubHeading: defineMessage({
          message: `Average student & parent rating`,
        }),
      },
      {
        ratingNumber: i18n.number(4.8),
        ratingSubHeading: defineMessage({ message: `Curriculum rating` }),
      },
    ],
    form: {
      gradeFieldPlaceholder: defineMessage({ message: `Select grade/class` }),
      phoneFieldLabel: defineMessage({
        message: `Enter your WhatsApp phone number`,
      }),
      ctaText: defineMessage({ message: `Try a free class` }),
      hasLaptopField: {
        label: defineMessage({
          message: `Do you have a laptop/desktop/MacBook for the class?`,
        }),
        option1: defineMessage({ message: `Yes` }),
        option2: defineMessage({ message: `No` }),
      },
    },
  },
  partnersFold: {
    builtBySectionHeading: defineMessage({ message: `Built by alumni of` }),
    partnershipSectionHeading: defineMessage({
      message: `In partnership with`,
    }),
    stemSectionHeading: defineMessage({ message: `Accredited by STEM.org` }),
    backedBySectionHeading: defineMessage({ message: `Backed by` }),
  },
  learnToCodeFold: {
    heading: {
      normalText: defineMessage({ message: `Learn to code` }),
      underlinedText: defineMessage({ message: `and get certificates` }),
    },
    subHeading: defineMessage({
      message: `Get certificates from prestigious global institutions and proudly show the world that you can code`,
    }),
  },
  testimonialsFold: {
    heading: {
      normalText: defineMessage({ message: `Students and parents` }),
      underlinedText: defineMessage({ message: `love Codingal` }),
      wholeHeadingInJsx: (
        <Trans id="homepage.testimonial.heading">
          Students and parents{' '}
          <span className="underlined-text">love Codingal</span>
        </Trans>
      ),
    },
    contents: ENGLISH_TESTIMONIAL_CONTENTS.default,
    reviewLinkText: defineMessage({ message: `See more reviews` }),
  },
  whyCodingalFold: {
    heading: {
      normalText: defineMessage({ message: `Why choose` }),
      underlinedText: defineMessage({ message: `Codingal` }),
    },
    contents: [
      {
        heading: defineMessage({ message: `Guided by` }),
        coloredHeading: defineMessage({ message: `expert instructors` }),
        imgLabel: 'guided-by-expert-instructors',
        subHeading: defineMessage({
          message: `Live online sessions with all-star computer science teachers who are trained to be the perfect mentors on your coding journey`,
        }),
      },
      {
        heading: defineMessage({ message: `Powered by the most` }),
        coloredHeading: defineMessage({ message: `scientific curriculum` }),
        imgLabel: 'powered-by-scientific-curriculum',
        subHeading: defineMessage({
          message: `The world's only coding curriculum based on BIDE, STEAM and Bloom's Taxonomy to teach kids coding scientifically`,
        }),
      },
      {
        heading: defineMessage({ message: `Pace of instruction that` }),
        coloredHeading: defineMessage({
          message: `matches your learning style`,
        }),
        imgLabel: 'pace-of-instruction-that-matches-your-learning-style',
        subHeading: defineMessage({
          message: `Customized pace to match your learning style and to make sure you get the maximum value from the course`,
        }),
      },
    ],
  },
  footer: {
    supprotHeading: defineMessage({ message: `Got questions? Write to us.` }),
  },
}
